const NavBack = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_642_3108)">
        <rect width="22" height="22" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.14674 10.3979C5.83432 10.7104 5.83432 11.2169 6.14674 11.5293C6.18537 11.568 6.22698 11.6018 6.27082 11.6309C6.30042 11.676 6.33506 11.7188 6.37474 11.7585L14.4358 19.8195C14.7482 20.132 15.2547 20.132 15.5671 19.8195C15.8795 19.5071 15.8795 19.0006 15.5671 18.6882L7.84319 10.9642L15.3391 3.4683C15.6515 3.15588 15.6515 2.64935 15.3391 2.33693C15.0267 2.02451 14.5202 2.02451 14.2078 2.33693L6.14674 10.3979Z"
          fill="#333333"
        />
      </g>
      <defs>
        <clipPath id="clip0_642_3108">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default NavBack
